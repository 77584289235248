import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';
import omit from 'lodash/omit';

const StyledSVG = styled(SVG)`
  width: ${({ size }) => size};
  height: ${({ size }) => size};

  ${({ theme, tabletSize }) => theme.tablet`
    width: ${tabletSize};
    height: ${tabletSize};
  `};

  ${({ theme, mobileSize }) => theme.phone`
    width: ${mobileSize};
    height: ${mobileSize};
  `};
`;

const DogIcon = ({ size, ...rest }) => {
  const { tabletSize, mobileSize } = rest;
  const copyRest = { ...rest, tabletSize: tabletSize || mobileSize };
  return (
    <StyledSVG
      size={size}
      src="/static/img/dog-circle.svg"
      {...omit(copyRest, DogIcon.OmitProps)}
    />
  );
};

DogIcon.propTypes = {
  size: PropTypes.string.isRequired,
  tabletSize: PropTypes.string,
  mobileSize: PropTypes.string.isRequired,
};

DogIcon.defaultProps = {
  tabletSize: null,
};

DogIcon.OmitProps = ['tabletSize', 'mobileSize'];

export default DogIcon;
